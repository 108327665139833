@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
*{
  font-family: 'Barlow', sans-serif;
}

#root, html, body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
}
.app-navbar-wrap .app-navbar-list {
  padding: 0;
}
.menuCustomV1 {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app-navbar-wrap nav ul .privateZoneMenu {
  padding: 10px 0;
  display: flex;
}

.app-navbar-wrap.collapsed nav ul .privateZoneMenu {
  display: flex;
}

.app-navbar-wrap nav ul .privateZoneMenu  > .coverImg {
  width: 40px;
  height: 40px;
  margin-left: 11px;
}

.app-navbar-wrap nav ul .privateZoneMenu  > .hideInside {
  flex: 1 1;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  padding-left: 10px;
  flex-direction: column;
}

.app-navbar-wrap.collapsed nav ul .privateZoneMenu  > .coverImg {

}

.app-navbar-wrap.collapsed nav ul .privateZoneMenu > .hideInside {
  display: none;
}

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #FFFFFF;
}
.login-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  max-width: 1000px;
  background-color: white;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  margin: 0 auto;
  border-radius: 12px;
}
#login-form {
  flex: 1 0 100%;
  max-width: 480px;
  width: 100%;
  padding: 60px;
}
#login-form p {
  margin-bottom: 30px;
}
#login-form p.form-title {
  color: #333333;
  font-family: "Josefin Sans", sans-serif;
  font-size: 42px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 0;
}
#login-form .ant-form-item-label > label.ant-form-item-required::before {
  display: none;
}
.ant-form-item-control-input-content {
  text-align: left;
}
#login-form .ant-input-affix-wrapper {
  padding: 12px 15px;
}
#login-form_username {
  height: 48px;
}
#login-form_2fa {
  height: 48px;
}
#login-form .ant-btn {
  height: 42px;
  letter-spacing: 1px;
  border-radius: 6px;
}
.login-form-button {
  width: 100%;
}
.illustration-wrapper {
  min-height: 100%;
  padding: 60px;
}
@media screen and (max-width: 1023px) {
  .login-box {
    flex-direction: column;
    box-shadow: none;
  }
  .illustration-wrapper {
    max-width: 100%;
    min-height: auto;
  }
  #login-form {
    max-width: 100%;
  }
}
@media screen and (max-width: 600px) {


  .app-navbar-wrap.collapsed nav ul .privateZoneMenu > .hideInside {
    display: flex;
  }

}

.site-result-demo-error-icon {
  color: red;
}

::-webkit-scrollbar {
  height: 0px;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: #335ddc;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #335ddc;
  border-radius: 8px;
}

.custom-row-hover:hover td {background: rgb(255, 235, 59)!important;}
.custom-row-hover:hover td {color: black; background: rgb(255, 235, 59)!important;}



.credit-card-input {font-size: 12px !important;}


/*
*{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
*/

.ant-table {
  margin: 0px !important;
}

.creditCardHolder {
  position: relative;
  background-size: cover;
  padding: 0px;
  padding-top: 0px;
  border-radius: 28px;
  margin: 0px 0;
  margin-top: 20px;
  margin-bottom: 9px;
  max-width: 370px;
  height: 95px;
  width: 100%;
}
.creditCardHolder header,
 .logo {
  display: flex;
  margin-top: -8px;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
 }
 .creditCardHolder .logo img {
  width: 40px;
  margin-right: 10px;
  margin-top: 0px;
}

.creditCardHolder h5 {
  font-size: 25px;
  font-weight: 400;
  margin: 10px 0;
  margin-top: 0px;
  margin-bottom: 0px;
}
.creditCardHolder header .chip {
  width: 45px;
}
.creditCardHolder h6 {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0px;
  margin-top: 10px;
  text-align: left;
}
.creditCardHolder h5.number {
  margin-top: 4px;
  font-size: 16px;
  letter-spacing: 1px;
  margin-bottom: 0;
  padding-right: 12px;
}
.creditCardHolder h5.name {
  margin-top: 20px;
}
.creditCardHolder .cardDetails {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.name-number {display: flex;flex-direction: column;}